import { useEffect, useMemo } from 'react';

const GridLayoutManager = () => {
    const breakpoints = useMemo(() => ({
        0: "xxs",
        414: "xs",
        568: "sm",
        768: "md",
        1024: "lg",
        1280: "xl",
        1440: "xxl",
        1600: "xxxl",
    }), []);


    useEffect(() => {
        function getGridLayoutClasses() {
            if (typeof window !== 'undefined' && typeof window.innerWidth === "number") {
                const width = window.innerWidth;
                const newLayoutClasses = [];

                for (const breakpointSize in breakpoints) {
                    if (width > Number(breakpointSize)) {
                        newLayoutClasses.push("layout-" + breakpoints[breakpointSize]);
                    }
                }

                const el = document.querySelector(".usaa-transactionalWrapper");
                if (el) {
                    el.className = "usaa-transactionalWrapper " + newLayoutClasses.join(" ");
                }
            }
        }

        function updatePageGridClasses() {
            getGridLayoutClasses();
        }

        updatePageGridClasses();

        window.addEventListener("resize", updatePageGridClasses);

        return () => {
            window.removeEventListener("resize", updatePageGridClasses);
        };
    }, [breakpoints]); // Include 'breakpoints' in the dependency array

    return null;
};

export default GridLayoutManager;
