import React, { useState } from 'react';

const CVVInput = ({ onChange }) => {
  const [cvv, setCVV] = useState('');

  const handleChange = (e) => {
    // Basic CVV formatting: Allow only digits and limit to a certain length
    const formattedCVV = e.target.value.replace(/\D/g, '').slice(0, 3);
    
    setCVV(formattedCVV);

    if (onChange) {
      onChange(formattedCVV);
    }
  };

  return (
    <input
      type="text"
      placeholder="CVV"
      value={cvv}
      onChange={handleChange}
      name="cvv"
      aria-describedby=""
      className="rds-text-input"
     
    />
  );
};

export default CVVInput;
