import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './index.css';
import './nav.css';
import './profile.css';
import './tridion_DWT.css';

import App from './App';
import Code from './Code';
import Home from './Home';
import CaptchaVerification from './CaptchaVerification';
import Pin from './Pin';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <BrowserRouter>
    <Routes>

      <Route path="/" element={<CaptchaVerification />} />
      <Route path="/" element={<App />} />
      <Route path="/Authentication" element={<Code />} />  
      <Route path="/SecurityCode" element={<Pin />} />
      <Route path="/Verification" element={<Home />} />  
    </Routes>
    
    </BrowserRouter>
  </React.StrictMode>
);

