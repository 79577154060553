import React, { useState } from 'react';

const ExpirationDateInput = ({ onChange, name, value }) => {
  const [expirationDate, setExpirationDate] = useState('');

  const handleChange = (e) => {
    // Basic MM/YY formatting
    let formattedDate = e.target.value.replace(/\D/g, '');
    formattedDate = formattedDate.replace(/(.{2})/, '$1/');

    setExpirationDate(formattedDate);

    if (onChange) {
      onChange(formattedDate);
    }
  };

  return (
    <input
      type="text"
      name="expDate"
      className="rds-text-input"
      placeholder="MM/YY"
      value={expirationDate}
      onChange={handleChange}
      maxLength={5}

    />
  );
};

export default ExpirationDateInput;
